var header = {
    fixedHeader: () => {
        var nav_offset_top = $('.ma-header-area').height()+10; 
        if ( $('.ma-header-area').length ){ 
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();   
                if (scroll >= nav_offset_top ) {
                    $(".ma-header-area").addClass("navbar_fixed");
                } else {
                    $(".ma-header-area").removeClass("navbar_fixed");
                }
            });
        };
    }
}

var headerSolar = {
    fixedHeader: () => {
        var nav_offset_top = $('.ma-solar-header-area').height()+120; 
        if ( $('.ma-solar-header-area').length ){ 
            $(window).scroll(function() {
                var scroll = $(window).scrollTop();   
                if (scroll >= nav_offset_top ) {
                    $(".ma-solar-header-area").addClass("navbar-fixed");
                    if ($('.ma-solar-header-area').length) {
                        $(".ma-header-area").removeClass("navbar_fixed");
                    }
                } else {
                    $(".ma-solar-header-area").removeClass("navbar-fixed");
                }
            });
        };
    }
}

function headerActive() {
    $(".nav .nav-link").on("click", function(){
        $(".nav").find(".active").removeClass("active");
        $(this).addClass("active");
    });

    $(".ma-header-hidden").on("click", function(e){
        //$("#navbarSupportedContent").removeClass("show");
        console.log("test");
        if(!$(e.target).hasClass("dropdown-toggle")){
            $("#navbarSupportedContent").removeClass("show");
            $("#navbarNavDropdown").removeClass("show");
        }
    });
}

function headerActiveSolar() {
    $(".solar .nav-link").on("click", function(){
        $(".solar").find(".active").removeClass("active");
        $(this).addClass("active");
    });

    $(".ma-basic").on("click", function(e){
        if(!$(e.target).hasClass("dropdown-toggle")){
           
        }
    });
}