function buttonToTop() {
    let mybutton = document.getElementById("btn-back-to-top");
    window.onscroll = function () {
      scrollFunction();
    };
  
    function scrollFunction() {
      if (document.body.scrollTop > 30 ||
        document.documentElement.scrollTop > 30) {
        mybutton.style.visibility = "visible";
      } else {
        mybutton.style.visibility = "hidden";
      }
    }
    
    mybutton.addEventListener("click", backToTop);
  
    function backToTop() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    }
  }